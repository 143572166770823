import i18next from 'i18next'

import { Colors } from '@vetahealth/fishing-gear/colors'
import {
  defaultUnits,
  getDisplayValue,
  normalize,
  normalizedUnitRanges,
  normalizedUnits,
} from '@vetahealth/fishing-gear/conversions'
import { CardDetails } from '@vetahealth/tuna-can-api'

import { LineChart } from '../Chart/LineChart'
import { createAlertField, createDeviceField } from '../Table/helpers'
import { GetResultsConfigParams, ResultsConfig } from './types'

export function getHeartRateVariabilityConfig({ userUnits }: GetResultsConfigParams): ResultsConfig {
  const unit = userUnits.heartRateVariability || defaultUnits.heartRateVariability

  const [min, max] = normalizedUnitRanges.heartRateVariability

  return {
    key: 'heartRateVariability',
    title: i18next.t('tracking.heartRateVariability.title'),
    category: 'tracking',
    unit,
    format: (event, targetUnit, omitUnit) =>
      event.value !== undefined
        ? getDisplayValue(
            [event.value, event.additionalValue ?? 0],
            event.unit ?? normalizedUnits.heartRateVariability,
            'heartRateVariability',
            { targetUnit, omitUnit },
          )
        : '–',
    actions: {
      add: [
        {
          key: 'value',
          label: i18next.t('tracking.heartRateVariability.heartRateVariability', { unit }),
          validators: [
            { required: true, message: i18next.t('validations.enterHeartRate') },
            {
              min,
              max,
              type: 'number',
              transform: (value) => normalize('heartRateVariability', Number(value), unit)[0],
              message: i18next.t('validations.trackingRange', { min, max, unit }),
            },
          ],
        },
      ],
      reviewBulk: true,
      downloadCsv: true,
    },
    chart: {
      component: LineChart,
      intervals: ['day', 'week', 'month', 'trend'],
      dataKeys: [{ key: 'value', color: Colors.azure500 }],
      getData: (data: CardDetails[]) => data,
    },
    table: {
      defaultFields: true,
      additionalFields: [createAlertField(), createDeviceField()],
      actions: ['review', 'delete'],
    },
  }
}
