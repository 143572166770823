import dayjs from 'dayjs'
import i18next from 'i18next'

import {
  BillingPeriod,
  CareStateEnum,
  MutedEnum,
  NotificationStatusEnum,
  SexEnum,
  StatusEnum,
  TrackingTypeEnum,
} from '@vetahealth/tuna-can-api'

import { CareStateCategoryEnum } from './types'

export function isTrackingType(type: string | undefined): type is TrackingTypeEnum {
  return !!type && Object.values<string>(TrackingTypeEnum).includes(type)
}

export const getCareStateNames: () => Record<CareStateEnum, string> = () => ({
  consentAttempted: i18next.t('careState.consentAttempted'),
  consented: i18next.t('careState.consented'),
  deviceOrdered: i18next.t('careState.deviceOrdered'),
  disqualified_advancedAge: i18next.t('careState.disqualifiedAdvancedAge'),
  disqualified_bedWheelchairBound: i18next.t('careState.disqualifiedBedWheelchairBound'),
  disqualified_cancerDiagnosis: i18next.t('careState.disqualifiedCancerDiagnosis'),
  disqualified_deceased: i18next.t('careState.disqualifiedDeceased'),
  disqualified_dialysis: i18next.t('careState.disqualifiedDialysis'),
  disqualified_managedBySpecialist: i18next.t('careState.disqualifiedManagedBySpecialist'),
  disqualified_medicallyUnstable: i18next.t('careState.disqualifiedMedicallyUnstable'),
  disqualified_nonCompliant: i18next.t('careState.disqualifiedNonCompliant'),
  disqualified_notMedicare: i18next.t('careState.disqualifiedNotMedicare'),
  disqualified_notSeenIn12Months: i18next.t('careState.disqualifiedNotSeenIn12Months'),
  disqualified_noInsuranceCoverage: i18next.t('careState.disqualifiedNoInsuranceCoverage'),
  disqualified_other: i18next.t('careState.disqualifiedOther'),
  disqualified_tooHighBmi: i18next.t('careState.disqualifiedTooHighBmi'),
  eligible: i18next.t('careState.eligible'),
  initialUpload: i18next.t('careState.initialUpload'),
  monitoring_concerningAnswers: i18next.t('careState.monitoringConcerningAnswers'),
  monitoring_deviceTroubleshooting: i18next.t('careState.monitoringDeviceTroubleshooting'),
  monitoring_medicalQuestion: i18next.t('careState.monitoringMedicalQuestion'),
  monitoring_outOfRange: i18next.t('careState.monitoringOutOfRange'),
  monitoring_stable: i18next.t('careState.monitoringStable'),
  none: i18next.t('careState.none'),
  notInterested_didntSeeTheValue: i18next.t('careState.notInterestedDidntSeeTheValue'),
  notInterested_providerNotInvolved: i18next.t('careState.notInterestedProviderNotInvolved'),
  notInterested_tooExpensive: i18next.t('careState.notInterestedTooExpensive'),
  notInterested_tooMuchTime: i18next.t('careState.notInterestedTooMuchTime'),
  notInterested_doesNotWantToParticipate: i18next.t('careState.notInterestedDoesNotWantToParticipate'),
  notInterested_overwhelmed: i18next.t('careState.notInterestedOverwhelmed'),
  onboarded: i18next.t('careState.onboarded'),
  pendingReferral: i18next.t('careState.pendingReferral'),
  qualified: i18next.t('careState.qualified'),
  registered: i18next.t('careState.registered'),
  soc: i18next.t('careState.soc'),
  waitingOnDelivery: i18next.t('careState.waitingOnDelivery'),
})

export const getCareStateCategoryName: () => Record<CareStateCategoryEnum, string> = () => ({
  disqualified: i18next.t('careState.disqualified'),
  monitoring: i18next.t('careState.monitoring'),
  notInterested: i18next.t('careState.notInterested'),
})

export const formatInterval = (interval: BillingPeriod): string =>
  `${dayjs(interval.startDate).format(i18next.t('dateFormats.dayAndMonth'))} - ${dayjs(interval.endDate).format(
    i18next.t('dateFormats.dayAndMonth'),
  )}`

export const getStatusLabel = (status: StatusEnum): string => {
  const translations: Record<StatusEnum, string> = {
    [StatusEnum.Active]: i18next.t('patientStatus.active'),
    [StatusEnum.Inactive]: i18next.t('patientStatus.inactive'),
    [StatusEnum.Locked]: i18next.t('patientStatus.locked'),
    [StatusEnum.Archived]: i18next.t('patientStatus.archived'),
  }
  return translations[status]
}

export const getSexLabel = (sex: SexEnum): string => {
  const translations: Record<SexEnum, string> = {
    [SexEnum.Male]: i18next.t('sex.male'),
    [SexEnum.Female]: i18next.t('sex.female'),
    [SexEnum.Other]: i18next.t('sex.other'),
  }
  return translations[sex]
}

export function getTrackingTitle(type: TrackingTypeEnum): string {
  const translations: Record<TrackingTypeEnum, string> = {
    [TrackingTypeEnum.BloodGlucose]: i18next.t('tracking.bloodGlucose.title'),
    [TrackingTypeEnum.BloodOxygen]: i18next.t('tracking.bloodOxygen.title'),
    [TrackingTypeEnum.BloodPressure]: i18next.t('tracking.bloodPressure.title'),
    [TrackingTypeEnum.HbA1c]: i18next.t('tracking.hbA1c.title'),
    [TrackingTypeEnum.HeartRate]: i18next.t('tracking.heartRate.title'),
    [TrackingTypeEnum.HeartRateVariability]: i18next.t('tracking.heartRateVariability.title'),
    [TrackingTypeEnum.RespiratoryRate]: i18next.t('tracking.respiratoryRate.title'),
    [TrackingTypeEnum.BodyTemperature]: i18next.t('tracking.bodyTemperature.title'),
    [TrackingTypeEnum.Sleep]: i18next.t('tracking.sleep.title'),
    [TrackingTypeEnum.Steps]: i18next.t('tracking.steps.title'),
    [TrackingTypeEnum.Weight]: i18next.t('tracking.weight.title'),
    [TrackingTypeEnum.Height]: i18next.t('tracking.height.title'),
  }
  return translations[type]
}

export function getNotificationStatusLabel(status: NotificationStatusEnum | MutedEnum): string {
  const translations: Record<MutedEnum | NotificationStatusEnum, string> = {
    [NotificationStatusEnum.NoPhone]: i18next.t('notificationStatus.noPhone'),
    [NotificationStatusEnum.NoProgram]: i18next.t('notificationStatus.noProgram'),
    [NotificationStatusEnum.PhoneInvalid]: i18next.t('notificationStatus.phoneInvalid'),
    [NotificationStatusEnum.ProgramNotStarted]: i18next.t('notificationStatus.programNotStarted'),
    [MutedEnum.UntilNextMeasurement]: i18next.t('notificationStatus.untilNextMeasurement'),
    [MutedEnum.Permanent]: i18next.t('notificationStatus.permanent'),
    [MutedEnum.None]: i18next.t('notificationStatus.none'),
  }
  return translations[status]
}
