import i18next from 'i18next'
import { DefaultTheme } from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { getDisplayValue } from '@vetahealth/fishing-gear/conversions'
import { DeviceStatusEnum, DeviceTypeEnum, OrderKitTypeEnum } from '@vetahealth/tuna-can-api'

export const getDeviceName = (type: DeviceTypeEnum | OrderKitTypeEnum): string => {
  const deviceNames: Record<DeviceTypeEnum | OrderKitTypeEnum, string> = {
    [DeviceTypeEnum.BioIntelliSenseButton]: i18next.t('devices.bioIntelliSenseButton'),
    [DeviceTypeEnum.BioIntelliSenseSticker]: i18next.t('devices.bioIntelliSenseSticker'),
    [DeviceTypeEnum.BodytraceBpCuff]: i18next.t('devices.bodytraceBpCuff'),
    [DeviceTypeEnum.GenericBpCuff]: i18next.t('devices.genericBpCuff'),
    [DeviceTypeEnum.BodytraceScale]: i18next.t('devices.bodytraceScale'),
    [DeviceTypeEnum.GenericScale]: i18next.t('devices.genericScale'),
    [DeviceTypeEnum.SmartMeterIScale]: i18next.t('devices.smartMeterIScale'),
    [DeviceTypeEnum.SmartMeterIGlucose]: i18next.t('devices.smartMeterIGlucose'),
    [DeviceTypeEnum.SmartMeterIPulseOx]: i18next.t('devices.smartMeterIPulseOx'),
    [DeviceTypeEnum.SmartMeterIBloodPressure]: i18next.t('devices.smartMeterIBloodPressure'),
    [DeviceTypeEnum.SmartMeterIBloodPressureXl]: i18next.t('devices.smartMeterIBloodPressureXL'),
    [DeviceTypeEnum.TenoviBpCuffS]: i18next.t('devices.tenoviBpCuffS'),
    [DeviceTypeEnum.TenoviBpCuffL]: i18next.t('devices.tenoviBpCuffL'),
    [DeviceTypeEnum.TenoviGlucometer]: i18next.t('devices.tenoviGlucometer'),
    [DeviceTypeEnum.TenoviPulseOx]: i18next.t('devices.tenoviPulseOx'),
    [DeviceTypeEnum.TenoviScale]: i18next.t('devices.tenoviScale'),
    [OrderKitTypeEnum.Standard]: i18next.t('orderKit.tenoviStandardKitWithoutWeight'),
    [OrderKitTypeEnum.Xl]: i18next.t('orderKit.tenoviXlKitWithoutWeight'),
  }
  return deviceNames[type]
}

export const getOrderDeviceName = (type: DeviceTypeEnum | OrderKitTypeEnum, preferredWeightUnit: string): string => {
  const deviceNames: Record<DeviceTypeEnum | OrderKitTypeEnum, string> = {
    [DeviceTypeEnum.BioIntelliSenseButton]: i18next.t('devices.bioIntelliSenseButton'),
    [DeviceTypeEnum.BioIntelliSenseSticker]: i18next.t('devices.bioIntelliSenseSticker'),
    [DeviceTypeEnum.BodytraceBpCuff]: i18next.t('devices.bodytraceBpCuff'),
    [DeviceTypeEnum.GenericBpCuff]: i18next.t('devices.genericBpCuff'),
    [DeviceTypeEnum.BodytraceScale]: i18next.t('orderDevices.bodytraceScale', {
      valueAndUnit: getDisplayValue(440, 'lbs', 'weight', preferredWeightUnit),
    }),
    [DeviceTypeEnum.GenericScale]: i18next.t('devices.genericScale'),
    [DeviceTypeEnum.SmartMeterIScale]: i18next.t('orderDevices.smartMeterIScale', {
      valueAndUnit: getDisplayValue(550, 'lbs', 'weight', preferredWeightUnit),
    }),
    [DeviceTypeEnum.SmartMeterIGlucose]: i18next.t('devices.smartMeterIGlucose'),
    [DeviceTypeEnum.SmartMeterIPulseOx]: i18next.t('devices.smartMeterIPulseOx'),
    [DeviceTypeEnum.SmartMeterIBloodPressure]: i18next.t('devices.smartMeterIBloodPressure'),
    [DeviceTypeEnum.SmartMeterIBloodPressureXl]: i18next.t('devices.smartMeterIBloodPressureXL'),
    [DeviceTypeEnum.TenoviBpCuffS]: i18next.t('devices.tenoviBpCuffS'),
    [DeviceTypeEnum.TenoviBpCuffL]: i18next.t('devices.tenoviBpCuffL'),
    [DeviceTypeEnum.TenoviGlucometer]: i18next.t('devices.tenoviGlucometer'),
    [DeviceTypeEnum.TenoviPulseOx]: i18next.t('devices.tenoviPulseOx'),
    [DeviceTypeEnum.TenoviScale]: i18next.t('devices.tenoviScale'),
    [OrderKitTypeEnum.Standard]: i18next.t('orderKit.tenoviStandardKit'),
    [OrderKitTypeEnum.Xl]: i18next.t('orderKit.tenoviXlKit'),
  }
  return deviceNames[type]
}

export const getStatusLabel = (status: DeviceStatusEnum): string => {
  const statusColors: Record<DeviceStatusEnum, string> = {
    [DeviceStatusEnum.Created]: i18next.t('orderStatus.created'),
    [DeviceStatusEnum.Submitted]: i18next.t('orderStatus.submitted'),
    [DeviceStatusEnum.Fulfilled]: i18next.t('orderStatus.fulfilled'),
    [DeviceStatusEnum.Delivered]: i18next.t('orderStatus.delivered'),
    [DeviceStatusEnum.Returned]: i18next.t('orderStatus.returned'),
    [DeviceStatusEnum.Assigned]: i18next.t('orderStatus.assigned'),
    [DeviceStatusEnum.InUse]: i18next.t('orderStatus.inUse'),
  }
  return statusColors[status]
}

export const getStatusColor = (status: DeviceStatusEnum, theme: DefaultTheme): Colors | undefined => {
  if (!status) return
  const statusColors: Record<DeviceStatusEnum, Colors> = {
    [DeviceStatusEnum.Created]: theme.tagCreated,
    [DeviceStatusEnum.Submitted]: theme.tagSubmitted,
    [DeviceStatusEnum.Fulfilled]: theme.tagFullfilled,
    [DeviceStatusEnum.Delivered]: theme.tagDelivered,
    [DeviceStatusEnum.Returned]: theme.tagReturned,
    [DeviceStatusEnum.Assigned]: theme.tagAssigned,
    [DeviceStatusEnum.InUse]: theme.tagSuccess,
  }
  return statusColors[status]
}

export const getStatusDescription = (status: DeviceStatusEnum): string => {
  if (!status) return ''
  const statusDescription: Record<DeviceStatusEnum, string> = {
    [DeviceStatusEnum.Created]: i18next.t('orderStatus.description.created'),
    [DeviceStatusEnum.Submitted]: i18next.t('orderStatus.description.submitted'),
    [DeviceStatusEnum.Fulfilled]: i18next.t('orderStatus.description.fulfilled'),
    [DeviceStatusEnum.Delivered]: i18next.t('orderStatus.description.delivered'),
    [DeviceStatusEnum.Returned]: i18next.t('orderStatus.description.returned'),
    [DeviceStatusEnum.Assigned]: i18next.t('orderStatus.description.assigned'),
    [DeviceStatusEnum.InUse]: i18next.t('orderStatus.description.inUse'),
  }
  return statusDescription[status]
}

export const tenoviDevices: Array<DeviceTypeEnum | OrderKitTypeEnum> = [
  DeviceTypeEnum.TenoviBpCuffS,
  DeviceTypeEnum.TenoviBpCuffL,
  DeviceTypeEnum.TenoviGlucometer,
  DeviceTypeEnum.TenoviPulseOx,
  DeviceTypeEnum.TenoviScale,
]

export const assignableDevices: DeviceTypeEnum[] = [
  DeviceTypeEnum.BodytraceBpCuff,
  DeviceTypeEnum.BodytraceScale,
  DeviceTypeEnum.SmartMeterIGlucose,
  DeviceTypeEnum.SmartMeterIPulseOx,
  DeviceTypeEnum.SmartMeterIScale,
  DeviceTypeEnum.SmartMeterIBloodPressure,
  DeviceTypeEnum.SmartMeterIBloodPressureXl,
  DeviceTypeEnum.TenoviBpCuffS,
  DeviceTypeEnum.TenoviBpCuffL,
  DeviceTypeEnum.TenoviGlucometer,
  DeviceTypeEnum.TenoviPulseOx,
  DeviceTypeEnum.TenoviScale,
]

export function getIsTenoviOrderForExistingDeviceType(
  existingDevices: Array<DeviceTypeEnum | OrderKitTypeEnum>,
  device: DeviceTypeEnum | OrderKitTypeEnum,
): boolean {
  const isOrderForExistingTenoviDevice = existingDevices.includes(device) && tenoviDevices.includes(device)
  const isBPCuff = device === DeviceTypeEnum.TenoviBpCuffL || device === DeviceTypeEnum.TenoviBpCuffS
  const isBPOrderForExistingDevice =
    existingDevices.includes(DeviceTypeEnum.TenoviBpCuffL) || existingDevices.includes(DeviceTypeEnum.TenoviBpCuffS)
  return isBPCuff ? isBPOrderForExistingDevice : isOrderForExistingTenoviDevice
}
