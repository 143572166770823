import i18next from 'i18next'

import { ChannelEnum, ConditionEnum, SexEnum, TrackingTypeEnum } from '@vetahealth/tuna-can-api'

import { SupportedLocale } from '../../lib/i18n'

export const getConditionNames = (): Record<ConditionEnum, string> => ({
  AVR: i18next.t('conditions.avr'),
  CABG: i18next.t('conditions.cabg'),
  CAP: i18next.t('conditions.cap'),
  Cardiomyopathy: i18next.t('conditions.cardiomyopathy'),
  Cellulitis: i18next.t('conditions.cellulitis'),
  ChronicHeartFailure: i18next.t('conditions.chronicHeartFailure'),
  COPD: i18next.t('conditions.copd'),
  COVID: i18next.t('conditions.covid'),
  CorPulmonale: i18next.t('conditions.corPulmonale'),
  Diabetes: i18next.t('conditions.diabetes'),
  DVTPE: i18next.t('conditions.dvtpe'),
  Hypertension: i18next.t('conditions.hypertension'),
  HypertensiveDisorderOfPregnancy: i18next.t('conditions.hypertensiveDisorderOfPregnancy'),
  MVR: i18next.t('conditions.mvr'),
  Obesity: i18next.t('conditions.obesity'),
  PulmonaryHTN: i18next.t('conditions.pulmonaryHtn'),
  Pyelonephritis: i18next.t('conditions.pyelonephritis'),
  Stroke: i18next.t('conditions.stroke'),
  TAVR: i18next.t('conditions.tavr'),
  UTI: i18next.t('conditions.uti'),
})

export const getSexNames = (): Record<SexEnum, string> => ({
  male: i18next.t('sex.male'),
  female: i18next.t('sex.female'),
  other: i18next.t('sex.other'),
})

export const unitsFromTrackingType: Record<TrackingTypeEnum, string[]> = {
  [TrackingTypeEnum.BloodGlucose]: ['mmol/l', 'mg/dl'],
  [TrackingTypeEnum.BloodOxygen]: ['%'],
  [TrackingTypeEnum.BloodPressure]: ['Pa', 'kPa', 'mmHg'],
  [TrackingTypeEnum.HbA1c]: ['mmol/mol', '%'],
  [TrackingTypeEnum.HeartRate]: ['/min'],
  [TrackingTypeEnum.HeartRateVariability]: ['ms'],
  [TrackingTypeEnum.Height]: ['m', 'cm', 'ft', 'ft_in'],
  [TrackingTypeEnum.RespiratoryRate]: ['/min'],
  [TrackingTypeEnum.BodyTemperature]: ['°C', '°F'],
  [TrackingTypeEnum.Sleep]: ['min', 'h'],
  [TrackingTypeEnum.Steps]: [],
  [TrackingTypeEnum.Weight]: ['g', 'kg', 'lb', 'lbs', 'st', 'st_lb', 'lb_oz'],
}

export const labelFromLocale: Record<SupportedLocale, string> = {
  [SupportedLocale.EN_US]: 'English (US)',
  [SupportedLocale.ES_419]: 'Español (América Latina)',
  [SupportedLocale.ES_US]: 'Español (US)',
  [SupportedLocale.DE_DE]: 'Deutsch',
}

export const getLabelFromChannel = (channel: ChannelEnum): string => {
  return {
    [ChannelEnum.Sms]: i18next.t('channel.sms'),
    [ChannelEnum.Call]: i18next.t('channel.call'),
    [ChannelEnum.Push]: i18next.t('channel.push'),
    [ChannelEnum.Email]: i18next.t('channel.email'),
    [ChannelEnum.None]: i18next.t('channel.none'),
  }[channel]
}
